.loader {
  display: inline-block;
  text-indent: -9999em;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #1E355E;
  background: linear-gradient(to right, #1E355E 10%, rgba(255, 255, 255, 0) 42%);
  overflow: hidden;
  position: relative;
  animation: load3 .65s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: #1E355E;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }

  &:after {
    background: #FFF;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.loaderWhite {
  background: #FFF;
  background: linear-gradient(to right, #FFF 10%, rgba(255, 255, 255, 0) 42%);

  &:before {
    background: #FFF;
  }

  &:after {
    background: #1E355E;
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'react-datepicker/dist/react-datepicker.css';

body {
  @apply font-body;

  font-size: 16px;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5 {
  @apply text-black;
  @apply font-display;
  @apply font-bold;
}

h1 {
  @apply text-2xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg;
}

h4 {
  @apply text-base;
}

h5 {
  @apply text-sm;
}

span,
p,
a,
div,
li {
  @apply text-sm;
}

.fab {
  @apply rounded-full;
  @apply h-24;
  @apply w-24;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.react-datepicker__close-icon::after {
  background-color: #fff;
  color: #000;
  font-size: 20px;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

/* Navbar */
.navmenu {
  min-height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.navmenu-inactive {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.navmenu-inactive-text {
  overflow-wrap: anywhere;
}

.navmenu.active {
  left: 0;
  transition: 350ms;
}

.menu_items {
  margin-top: 5rem;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  height: 60px;
}

.nav-text a {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  border-radius: 4px;
}

.menu_item_active {
  background-color: rgba(255, 255, 255, 0.2);
}

.navbar_toggle {
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Member Profile Dashbaord */
.stat-text {
  @apply text-4xl;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0;
  text-align: center;
}

/* Member Profile History */
.searchableInput {
  min-width: 200px;
}

.searchableInput div[class$='-control'] {
  border-radius: 0;
}

.searchableInput svg,
.searchableInput div[class$='-placeholder'] {
  @apply text-black;
}

.searchableInput span[class$='-indicatorSeparator'] {
  width: 0;
}

/* Member Profile Memos */
.priority-text[data-status='High'] {
  @apply text-red;
}

.priority-text[data-status='Medium'] {
  @apply text-green-200;
}

.priority-text[data-status='Low'] {
  @apply text-yellow-200;
}

.content-container {
  flex-grow: 1;
  height: 100%;
}
